const rules={
    phone:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
    email:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
    bankCard:/^\d+$/,
    IDCard:/^[1-9]\d{5}/,
    passowrd:new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}'), // 密码中必须包含字母、数字，至少8个字符，最多30个字符
}




export function phone(rules, value, callback) {
    const reg = /\d+/
    if (value.length != 11) {
        callback(new Error('手机号必须是11位'))
    } else if (!reg.test(value)) {
        callback(new Error('手机号码有误'))
    } else {
        callback()
    }
}

export function identificationNumber(rule, value, callback) {
    const reg = /^([A-Z]|\d)+$/
    if (value.length < 15 || value.length > 18) {
        callback(new Error('证件号必须是15或者18位'))
    } else if (!reg.test(value)) {
        callback(new Error('证件号格式不正确'))
    } else {
        callback()
    }


}

export function bankNumber(rule, value, callback) {
    // const reg = /^\d+$/
    if (value.length < 16 || value.length > 19) {
        callback(new Error('银行卡号必须是16或者19位'))
    } else if (!rules.bankCard.test(value)) {
        callback(new Error('银行卡号码错误'))
    } else {
        callback()
    }
}

export function checkRate(rule, value, callback) {
    if ('' == rule.field) {
        callback(new Error('费率必填'))
    } else if (Number(rule.field) > 100 || Number(rule.field) < 0) {
        callback(new Error('费率值错误'))
    } else {
        callback()
    }
}

// const complex=/^[A-Za-z0-9]{6,20}$/;
// 密码中必须包含字母、数字，至少8个字符，最多30个字符。
// const regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}');
// 密码中必须包含字母、数字、特称字符，至少8个字符，最多30个字符。
// const regexIntermediate = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}');
// 密码中必须包含大小字母、数字、特称字符，至少8个字符，最多30个字符。
// const bigregex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');

export function validatePass(rule,value,callback){
    if (value === '') {
        callback(new Error('请输入密码'));
    }else {
        if (rules.passowrd.test(value)) {
            callback()
        }else{
            callback(new Error('密码中必须包含字母、数字，至少8个字符'))
        }
    }
} 
