<script setup>
import { reactive, ref, onMounted, getCurrentInstance, nextTick, onUpdated } from "vue"
// import { Plus, CopyDocument,Check, Close } from '@element-plus/icons-vue'
import {Plus, CopyDocument, ArrowLeft} from '@element-plus/icons-vue'
import dayjs from "dayjs"
import { ElNotification, ElMessage } from 'element-plus'
import { validatePass } from "@/tools/validatorFunc.js"

const addAgentFormRef = ref(null)

const { appContext } = getCurrentInstance()

let isloading = ref(true)

const pageInfo = reactive({
  page: 1, // 当前页
  total: 0, // 共计
  perPage: 0, // 每页多少条数据
})
const pageInfoNotFirstLevel = reactive({
  page: 1, // 当前页
  total: 0, // 共计
  perPage: 0, // 每页多少条数据
})

// 用户信息临时存放
let userinfo = reactive({
  id: "",
  device_ids: "",
})

const handleDevice = (data) => {
  userinfo.id = data.id
  queryBoundDevice(data.id)
  setTimeout(() => {
    assingnBtn.value = !assingnBtn.value
  }, 500)
}

const handleReset = () => {
  rootQueryParams.value.agent_id = ""
  isNowOnFirstLevel.value = true
  queryFirstLevel()
}

const handleSearch = () => {
  queryFirstLevel()
}

const isshow = ref(false)
const handleAdd = () => {
  isshow.value = !isshow.value
}

let addNewDate = reactive({
  company: '',
  account_type: '',
  account_no: '',
  account_name: '',
  name: '',
  phone: '',
  password: '',
})


const closeAddDialog = () => {
  isshow.value = false
  addAgentFormRef.value.resetFields()
}

// 添加代理商
const confirmAdd = () => {
  addAgentFormRef.value.validate(valid => {
    if (valid) {
      appContext.config.globalProperties.$api.addAgent(addNewDate).then(res => {
        if (res.status === 200) {
          ElNotification({title: 'Success',message: '添加代理商成功',type: 'success'})
          // queryAll({parent_id:"null"})
          queryFirstLevel()
          isshow.value = false
          addAgentFormRef.value.resetFields()
        }else if(res.error.message!=null){
          ElMessage({message: res.error.message,type: 'warning'})
        } else {
          ElNotification({title: 'Error',message: '添加代理商失败',type: 'error'})
        }
      })
    }
  })
}

const validatePhone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入联系电话'))
  } else if (!(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value))) {
    callback(new Error('手机号格式错误'))
  } else {
    callback()
  }
}

// 添加代理商表单校验规则
const addAgentFormRules = reactive({
  company: [
    { required: true, message: '请输入代理商名称', trigger: 'blur' }
  ],
  account_type: [
    { required: true, message: '请选择账户类型', trigger: 'blur' }
  ],
  account_no: [
    { required: true, message: '请输入收款人账号', trigger: 'blur' }
  ],
  account_name: [
    { required: true, message: '请输入收款人名称', trigger: 'blur' }
  ],
  name: [
    { required: true, message: '请输入联系人', trigger: 'blur' }
  ],
  phone: [
    { required: true, validator: validatePhone, trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { validator: validatePass, trigger: 'blur' }
  ],
})

const agentsData = reactive([])

// 查询全部代理商数据
const queryFirstLevel = () => {
  isloading.value = true
  queryingDeviceTypes()
  appContext.config.globalProperties.$api.agentsList(rootQueryParams.value).then(res => {
    isNowOnFirstLevel.value = true
    if (res.status === 200) {
      isloading.value = !isloading.value
      agentsData.length = 0;
      agentsData.push(...res.data)
      pageInfo.page = res.pagination.currentPage
      pageInfo.total = res.pagination.total
      pageInfo.perPage = res.pagination.perPage
    } else if(res.status==502||res.status==504) {
      isloading.value = !isloading.value
      ElMessage({message: '请求超时，请稍后重试',type: 'success',})
    }else{
      isloading.value=!isloading.value
    }
  })
  isloading.value = true
}
const rootQueryParams = ref({
  agent_id: "",
})

const pageChange = (page) => {
    rootQueryParams.value.page = page
    queryFirstLevel()
}
const pageChangeNotFirstLevel = (page) => {
  notFirstLevelQueryParams.value.page = page
  queryChildList(notFirstLevelQueryParams.value)
}

const updateisshow = ref(false)

let updateNewDate = reactive({
  company: '',
  account_type: '',
  account_no: '',
  account_name: '',
  name: '',
  phone: '',
  password: ''
})

let agentId;

const handleEdit = (id) => {
  agentId = id;
  appContext.config.globalProperties.$api.agentsInfo(id).then(res => {
    updateNewDate.company = res.data.company
    updateNewDate.account_type = res.data.account_type
    updateNewDate.account_no = res.data.account_no
    updateNewDate.account_name = res.data.account_name
    updateNewDate.name = res.data.name
    updateNewDate.phone = res.data.phone
    updateNewDate.password = res.data.password
    updateNewDate.parent_id=res.data.parent_id
    updateNewDate._method = 'put'
    updateisshow.value = true
  })
}

let updateAgentFormRef = ref(null)

const closeupdateDialog = () => {
  updateisshow.value = false
  addAgentFormRef.value.resetFields()
}

const confirmUpdate = () => {
  updateAgentFormRef.value.validate(valid => {
    if (valid) {
      appContext.config.globalProperties.$api.updateAgentsInfo(agentId, updateNewDate).then(res => {
        if (res.status === 200) {
          ElNotification({
            title: '修改成功',
            message: '修改代理商成功',
            type: 'success',
          })
          queryFirstLevel()
        } else {
          ElNotification({
            title: '修改失败',
            message: '修改代理商失败',
            type: 'error',
          })
        }
        updateisshow.value = false
        updateAgentFormRef.value.resetFields()
      })
    }
  })
}


// 分配设备
const assingnBtn = ref(false)

// 已绑定数据
let boundData = ref()
let boundPage = reactive({
  page: 1,
  total: 0,
  currentPage: 0,//当前页,
  totalPages: 0
})

// 设备分类
let deviceTypes = reactive([])

const bindisshow = ref(false)

// 解绑
const handleUnbind = (data) => {
  let device_ids = [data.id]
  appContext.config.globalProperties.$api.agents.unBund(data.agent_id, { device_ids: device_ids }).then(res => {
    if (res.status == 200) {
      ElMessage({
        message: '解绑成功',
        type: 'success',
      })
      queryBoundDevice(data.agent_id)
    } else {
      ElMessage.error({ message: '解绑失败，请稍候重试', })
    }
  })
}

const handleCopy = (data) => {
  ElMessage({
    message: '复制成功',
    type: 'success',
  })
  let tempcopy = document.createElement("input")
  tempcopy.value = data
  document.body.appendChild(tempcopy)
  tempcopy.select()
  document.execCommand('Copy'),
  document.body.removeChild(tempcopy); // 复制完成后，移除临时输入框
}

// 绑定
const multipleTableRef = ref()

let selectdata = ref()

const handleBindConfirm = () => {
  let bindDeviceData = {
    id: userinfo.id,
    device_ids: []
  }
  selectdata.value.map(item => {
    bindDeviceData.device_ids.push(item.id)
  })
  appContext.config.globalProperties.$api.agents.bindingDevice(userinfo.id, bindDeviceData).then(res => {
    if (res.status == 200) {
      handleBoundShow()
      ElMessage({
        message: '绑定成功.',
        type: 'success',
      })
    } else {
      ElMessage.error('绑定失败,请稍候重试')
    }
  })
}

const handleSelectionChange = (data) => {
  selectdata.value = data
}

// 查询分类
const queryingDeviceTypes = () => {
  queryPayMentAll()
  appContext.config.globalProperties.$api.agents.deviceType().then(res => {
    if (res.status == 200) {
      deviceTypes = res.data.data
    }
  })
}


// 配置费率
const rateConfigBtn = ref(false)

const activeNum = ref(6)

let paymentListAll = ref()

let selectBar = ref()

let agent_id = ref()


const handleConfig = (data) => {
  QueryRateinfo(data.id)
  agent_id.value = data.id
}

// 查询所有的支付方式
const queryPayMentAll = () => {
  appContext.config.globalProperties.$api.payment.paymentList().then(res => {
    if (res.status == 200) {
      paymentListAll.value = res.data.data
    }
  })
}


// 查询费率信息
const QueryRateinfo = (id) => {
  appContext.config.globalProperties.$api.QueryRate(id).then(res => {
    if (res.status == 200) {
      let temp = res.data.agent_rates
      let topbar = paymentListAll.value
      let extra_cost = ""
      // 数据处理
      temp.forEach(item => {
        topbar.forEach(ele => {
          // 判断渠道
          if (item.payment_channel_id == ele.id) {
            ele.payment_methods.forEach(list => {
              // 判断支付方式
              if (list.id == item.payment_method_id) {
                extra_cost = item.extra_cost
                if (typeof (item.cost) == 'object') {
                  list.pivot.cost = { ...item.cost }
                  list.pivot.extra_cost = extra_cost
                } else {
                  list.pivot.cost = item.cost
                  list.pivot.extra_cost = extra_cost
                }
              }
            })
          }
        })
      })
      selectBar.value = topbar
      rateConfigBtn.value = !rateConfigBtn.value
    } else {
      ElMessage({
        message: '数据查询失败! 请稍后重试',
        type: 'error',
      })
    }
  })
}

// 保存配置未绑定
const handleSeveConfig = (data) => {
  let Paymethods = data.payment_methods
  let value = []
  Paymethods.map(item => {
    let format = {}
    if (typeof (item.pivot.cost) == 'object') {
      format = {
        id: item.id,
        cost: { ...item.pivot.cost },
        extra_cost: item.pivot.extra_cost || 0
      }
    } else {
      format = {
        id: item.id,
        cost: item.pivot.cost,
        extra_cost: item.pivot.extra_cost || 0
      }
    }
    value.push(format)
  })

  let results = {
    agent_id: agent_id.value,
    payment_channel_id: data.id,    // 支付渠道id
    payment_methods: value          // 配置费率信息
  }
  SendRate(results.agent_id, results)
}

// 配置费率请求
const SendRate = (id, data) => {
  appContext.config.globalProperties.$api.setrate(id, data).then(res => {
    if (res.status == 200) {
      ElNotification({
        title: '修改成功',
        message: '数据修改成功',
        type: 'success',
      })
      QueryRateinfo(id)
      queryFirstLevel()
    } else {
      ElNotification({
        title: `错误代码: ${res.error.code},请检查以下内容后重试`,
        message: res.error.fields,
        type: 'error',
      })
    }
  })
}


// 实例化
let unboundTable = ref()
let unboundpage = reactive({
  count: 0,
  currentPage: 0,
  perPage: 0,
  total: 0,
  totalPages: 0
})


// 未绑定
const handleundbindPage = (page) => {
  handleBoundShow(page)
}

const handleBoundShow = (data) => {
  bindisshow.value = !bindisshow.value
  let initial = {
    is_bind_agent: 0,
    page: data
  }
  appContext.config.globalProperties.$api.agents.bindDevices(initial).then(res => {
    unboundTable.value = res.data.data
    unboundpage.count = res.data.pagination.count
    unboundpage.currentPage = res.data.pagination.currentPage
    unboundpage.perPage = res.data.pagination.perPage
    unboundpage.total = res.data.pagination.total
    unboundpage.totalPages = res.data.pagination.totalPages
  })
}


const handleboundPage = (page) => {
  queryBoundDevice(userinfo.id, { page: page })
}

// 查询已绑定的设备
const queryBoundDevice = (id, data) => {
  appContext.config.globalProperties.$api.agents.queryBind(id, data).then(res => {
    boundData.value = res.data.data
    boundPage.total = res.data.pagination.total
    boundPage.page = res.data.pagination.perPage
    boundPage.currentPage = res.data.pagination.currentPage
    boundPage.totalPages = res.data.pagination.totalPages
    deviceTypes.forEach((elment) => {
      boundData.value.forEach((item => {
        if (elment.device_category_id == item.device_category_id) {
          item.device_category_name = elment.device_category.name
          item.type = elment.type
          item.device_category_id = elment.device_category_id
        }
      }))
    })
  })
}

const optionsAgent = ref([])
const getOptionsAgent = (data) => {
  appContext.config.globalProperties.$api.dict.getAgentDict(data).then(res => {
    if (res.status == 200) {
      optionsAgent.value = res.data.data
    }
  })
}

onMounted(() => {
  queryFirstLevel()
  getOptionsAgent()
})

onUpdated(() => {
  nextTick(() => {
    queryPayMentAll()
  })
})

const isNowOnFirstLevel = ref(true)
const notFirstLevelQueryParams = ref({
    parent_id: "",
})
const lastLevelPidStack = ref([])
const onShowLastLevel = () => {
  const lastLevelPid  = lastLevelPidStack.value.pop()
  if (!lastLevelPid) {
    queryFirstLevel()
    return
  }
  notFirstLevelQueryParams.value.parent_id = lastLevelPid
  queryChildList(notFirstLevelQueryParams.value)
}
const onShowNextLevel =  (row)=>{
  isNowOnFirstLevel.value = false
  notFirstLevelQueryParams.value.parent_id = row.id
  lastLevelPidStack.value.push(row.parent_id)
  queryChildList(notFirstLevelQueryParams.value)
}
const onClickParentAgent = (parentAgent, event) => {
  event.preventDefault();
  if (!parentAgent) {
    return
  }
  rootQueryParams.value.agent_id = parentAgent.id
  queryFirstLevel()
}
const queryChildList = (data)=>{
  appContext.config.globalProperties.$api.agentsChild(data).then(res => {
    if (res.status==200) {
      agentsData.length = 0;
      agentsData.push(...res.data)
      pageInfoNotFirstLevel.page = res.pagination.currentPage
      pageInfoNotFirstLevel.total = res.pagination.total
      pageInfoNotFirstLevel.perPage = res.pagination.perPage
    }
  })
}

const agentOption=ref()

//代理商id
const AgentID=ref()

// 分配通道
const channelShow=ref(false)
const handleChannel =(row)=>{
  channelShow.value=true
  AgentID.value=row
  queryAgentChannel({agent_id:row})
}

const channelData=ref([])
// 查询通道情况
const queryAgentChannel=(data)=>{
  appContext.config.globalProperties.$api.agentChannel.query(data).then(res=>{
    if (res.status==200) {
      channelData.value=res.data.data
    }else{
      ElMessage.error("查询失败")
    }
  })
}


const addChannelShow=ref(false)

const handleAddChannel=()=>{
  queryChannel()
  addChannelShow.value=!addChannelShow.value
}

const addChanneData=reactive({
  agent_id:AgentID.value,
  config:{ }
})

const channel_options=ref()
// 查询通道
const queryChannel=()=>{
  appContext.config.globalProperties.$api.payment.paymentList().then(res => {
    if (res.data.status == 200) {
      channel_options.value=res.data.data
    }
  })
}

const handelSeveChanneData=(status)=>{
  if (status) {
    let data={...addChanneData}
    data.agent_id=AgentID.value
    changeData(data)
  }else{
    const data={
      status:editChannelData.value.status,
      config:editChannelData.value.config
    }
    appContext.config.globalProperties.$api.agentChannel.edit(editChannelData.value.id,data).then(res=>{
      if (res.status===200) {
        ElMessage({message:'修改成功',type: 'success'})
        editChannelShow.value=false
        queryAgentChannel({agent_id:AgentID.value})
      }else{
        ElMessage.error("修改失败")
        ElMessage.error(res.error.message)
      }
    })
  }
}

function changeData(data){
  appContext.config.globalProperties.$api.agentChannel.new(data).then(res=>{
    if (res.status==200) {
      ElMessage({message:'新增成功',type: 'success'})
      addChannelShow.value=false
      queryAgentChannel({agent_id:AgentID.value})
    }else if(res.status==422){
      ElMessage.error("格式错误，请检查是否为空")
    }else{
      ElMessage.error(res.error.message)
      queryAgentChannel({agent_id:AgentID.value})
    }
  })
}

const hanledeleteChannel=(row)=>{
  appContext.config.globalProperties.$api.agentChannel.delete({id:row.id}).then(res=>{
    if (res.status==200) {
      ElMessage({message:'删除成功',type: 'success'})
      queryAgentChannel({agent_id:AgentID.value})
    }else if(res.status==422){
      ElMessage.error("格式错误，请检查是否为空")
    }else{
      ElMessage.error("删除失败")
      queryAgentChannel({agent_id:AgentID.value})
    }
  })
}


const editChannelShow=ref(false)
const editChannelData=ref()
const hanledChangeChannel=(row)=>{
  appContext.config.globalProperties.$api.agentChannel.queryinfo({id:row.id}).then(res=>{
    if (res.status==200) {
      let data= {...res.data.data}
      editChannelData.value=data
      editChannelShow.value=!editChannelShow.value
    }
  })
}
const statusOptiont=[
  {label:"开启",value:1},
  {label:"关闭",value:2}
]
</script>

<template>
  <div class="container">
    <!-- 顶部搜索 -->
    <el-card shadow="never">
      <div class="top-search">
        <el-form :inline="true" :model="rootQueryParams">
          <el-form-item label="代理商">
            <el-select v-model="rootQueryParams.agent_id" placeholder="请选择" filterable clearable
                       style="width: 90%;" >
              <el-option v-for="option in optionsAgent" :key="option.id"
                         :label="option.company + ' | ' + option.name"
                         :value="option.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号">
            <el-select v-model="rootQueryParams.agent_id" placeholder="请选择" filterable clearable
                       style="width: 90%;" >
              <el-option v-for="option in optionsAgent" :key="option.id"
                         :label="option.phone + ' | ' + option.name"
                         :value="option.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="search-btn">
          <el-button @click="handleReset">重置</el-button>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </div>
      </div>
    </el-card>
    <!-- 顶部搜索 end-->
    <!-- 列表数据 -->
    <div class="list">
      <div class="list-top">
        <h2>代理商列表</h2>
        <div>
          <el-button type="primary" plain @click="onShowLastLevel" :icon="ArrowLeft" v-if="!isNowOnFirstLevel">
            返回上级
          </el-button>
          <el-button type="primary" @click="handleAdd()">
            <el-icon style="margin-right: 0.625rem; color: #ffffff;">
              <Plus />
            </el-icon>
            新增代理商
          </el-button>
        </div>
      </div>
      <el-table :data="agentsData" row-key="no" style="width: 100%" v-loading="isloading" size="small"  :tree-props="{ children: 'children'}">
        <el-table-column prop="company" label="代理商名称" />
        <el-table-column prop="no" label="代理商编号" />
        <el-table-column prop="name" label="联系人" width="180" />
        <el-table-column prop="phone" label="联系电话" width="180" />
        <el-table-column prop="depth" label="层级" align="center" >
          <template #default="scope">
            <el-tag type="danger"> {{ scope.row.depth == 0 ? '一级' : scope.row.depth == 1 ? '二级' : '三级' }} </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="account_type" label="账户类型" align="center">
          <template #default="scope">
            <el-tag v-if="scope.row.account_type==1" type="success">对公</el-tag>
            <el-tag v-else type="warning">对私</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="parent_name" label="上级代理" align="center">
          <template #default="scope">
            <a
               @click="(event) => onClickParentAgent(scope.row?.parent_agent, event)"
               href="#"
               v-if="scope.row?.parent_agent">
              {{ scope.row?.parent_agent?.company || '-' }}
            </a>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间">
          <template #default="scope">
            <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD")}} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <!-- <div class="control-btn" v-if="scope.row.depth==0"> -->
            <div class="control-btn">
              <el-link :underline="false" type="primary" @click="onShowNextLevel(scope.row)" v-if="scope.row.depth<2">下级</el-link>
              <el-link @click="handleChannel(scope.row.id)" :underline="false" type="primary">通道</el-link>
              <el-link @click="handleDevice(scope.row)" :underline="false" type="primary">设备</el-link>
              <span>
                <el-dropdown>
                  <span class="el-dropdown-link">...</span>
                  <template #dropdown>
                    <el-dropdown-item @click="handleEdit(scope.row.id)">修改信息</el-dropdown-item>
                    <el-dropdown-item @click="handleConfig(scope.row)">配置费率</el-dropdown-item>
                    <!-- <el-dropdown-item @click="handleDevice(scope.row)">分配设备</el-dropdown-item> -->
                    <!-- <el-dropdown-item @click="handleChannel(scope.row)">分配通道</el-dropdown-item> -->
                  </template>
                </el-dropdown>
              </span>
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="200" />
        </template>
      </el-table>
    </div>
    <!-- 列表数据 end -->
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background layout="total, prev, pager, next" v-if="isNowOnFirstLevel"
                     :total=pageInfo.total @current-change="pageChange"
                     :page-size=pageInfo.perPage :current-page=pageInfo.page />
      <el-pagination background layout="total, prev, pager, next" v-else
                     :total=pageInfoNotFirstLevel.total @current-change="pageChangeNotFirstLevel"
                     :page-size=pageInfoNotFirstLevel.perPage :current-page=pageInfoNotFirstLevel.page />
    </div>
    <!-- 分页 end -->
    <!-- 新增 -->
    <el-dialog v-model="isshow" title="新增代理商" width="34%">
      <el-form :model="addNewDate" label-width="120px" :rules="addAgentFormRules" ref="addAgentFormRef">
        <el-form-item label="代理商名称：" prop="company">
          <el-input v-model="addNewDate.company" placeholder="请输入代理商名称" />
        </el-form-item>
        <el-form-item label="账户类型：" prop="account_type">
          <el-select v-model="addNewDate.account_type" class="m-2" placeholder="请输入账户类型">
            <el-option label="对公" :value="1" />
            <el-option label="对私" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="收款人账号：" prop="account_no">
          <el-input v-model="addNewDate.account_no" placeholder="请输入收款人账号" />
        </el-form-item>
        <el-form-item label="收款人名称：" prop="account_name">
          <el-input v-model="addNewDate.account_name" placeholder="请输入收款人名称" />
        </el-form-item>
        <el-form-item label="联系人：" prop="name">
          <el-input v-model="addNewDate.name" placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="联系电话：" prop="phone">
          <el-input v-model="addNewDate.phone" placeholder="请输入联系电话" />
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input v-model="addNewDate.password" placeholder="请输入密码" type="password" show-password />
        </el-form-item>
        <div class="dialog-btn" style="text-align: right;">
          <el-button @click="closeAddDialog">取消</el-button>
          <el-button type="primary" @click="confirmAdd">确认</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 新增 end -->
    <!-- 修改 -->
    <el-dialog v-model="updateisshow" title="修改代理商" width="34%">
      <el-form :model="updateNewDate" label-width="120px" :rules="addAgentFormRules" ref="updateAgentFormRef">
        <el-form-item label="代理商名称：" prop="company">
          <el-input v-model="updateNewDate.company" placeholder="请输入代理商名称" />
        </el-form-item>
        <el-form-item label="账户类型：" prop="account_type">
          <el-select v-model="updateNewDate.account_type" class="m-2" placeholder="请选择账户类型">
            <el-option label="对公" :value="1" />
            <el-option label="对私" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="收款人账号：" prop="account_no">
          <el-input v-model="updateNewDate.account_no" placeholder="请输入收款人账号" />
        </el-form-item>
        <el-form-item label="收款人名称：" prop="account_name">
          <el-input v-model="updateNewDate.account_name" placeholder="请输入收款人名称" />
        </el-form-item>
        <el-form-item label="联系人：" prop="name">
          <el-input v-model="updateNewDate.name" placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="联系电话：" prop="phone">
          <el-input v-model="updateNewDate.phone" placeholder="请输入联系电话" />
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input v-model="updateNewDate.password" placeholder="请输入密码" />
        </el-form-item>
        <el-form-item label="上级代理商:" prop="parent_id">
          <el-select v-model="updateNewDate.parent_id" placeholder="请选择上级代理商" filterable remote reserve-keyword>
            <el-option v-for="(item,index) in agentOption" :key="index" :value="item.id" :label="item.name" />
          </el-select>
        </el-form-item>
        <div class="dialog-btn" style="text-align: right;">
          <el-button @click="closeupdateDialog">取消</el-button>
          <el-button type="primary" @click="confirmUpdate">确认</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 修改 end -->
    <!-- 配置费率 -->
    <el-dialog v-model="rateConfigBtn" title="成本费率配置" width="60%">
      <el-card shadow="never" class="table-card">
        <el-tabs v-model="activeNum">
          <el-tab-pane v-for="item in selectBar" :key="item.id" :label="item.name" :name="item.id">
            <div v-if="item.id==item.id" class="card-block">
              <el-form :model="selectBar" label-width="200px" style="align-items: center;">
                <div v-for="(list,index) in item.payment_methods" :key="index">
                  <div v-if="list.code=='alipay'||list.code=='wxpay'||list.code=='yzfpay'||list.code=='recharge'">
                    <el-card shadow="hover">
                      <template #header>
                        <div class="card-header"><span>{{list.name}}</span></div>
                      </template>
                      <div class="card-form">
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="费率(%):">
                              <el-input v-model="list.pivot.cost" />
                              <span class="describe">成本 {{list.cost}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="额外收取下级分润比例：">
                              <el-input v-model="list.pivot.extra_cost" />
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </div>
                    </el-card>
                    <br />
                  </div>
                  <div v-if="list.code=='unionpay'">
                    <el-card shadow="hover">
                      <template #header>
                        <div class="card-header"><span>{{ list.name }}</span></div>
                      </template>
                      <div class="card-form">
                        <el-row>
                          <el-col :span="12">
                            <el-form-item label="借记卡1000.00以下（含）：">
                              <el-input v-model="list.pivot.cost.debitcard.cost" placeholder="请输入成本费率" />
                              <span class="describe">成本 {{ list.cost.debitcard.cost}} </span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1">
                            <p class="percentage">%</p>
                          </el-col>
                          <el-col :span="8">
                            <el-input v-model="list.cost.debitcard.total" placeholder="请输入成本费" />
                            <span class="describe">成本 {{ list.cost.debitcard.total}}</span>
                          </el-col>
                          <el-col :span="2">
                            <p class="percentage">元</p>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="12">
                            <el-form-item label="借记卡1000.00以上：">
                              <el-input v-model="list.pivot.cost.debitcard_outer.cost" placeholder="请输入成本费率" />
                              <span class="describe">成本 {{list.cost.debitcard_outer.cost}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1">
                            <p class="percentage">%</p>
                          </el-col>
                          <el-col :span="8">
                            <el-input v-model="list.pivot.cost.debitcard_outer.total" placeholder="请输入成本" />
                            <span class="describe">成本 {{list.cost.debitcard_outer.total}}</span>
                          </el-col>
                          <el-col :span="2">
                            <p class="percentage">元</p>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="12">
                            <el-form-item label="信用卡1000.00以下（含）：">
                              <el-input v-model="list.pivot.cost.creditcard.cost" placeholder="请输入成本费率" />
                              <span class="describe">成本 {{list.cost.creditcard.cost}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1">
                            <p class="percentage">%</p>
                          </el-col>
                          <el-col :span="10"></el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="12">
                            <el-form-item label="信用卡1000.00以上：">
                              <el-input v-model="list.pivot.cost.creditcard_outer.cost" placeholder="请输入成本" />
                              <span class="describe">成本 {{list.cost.creditcard_outer.cost}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1">
                            <p class="percentage">%</p>
                          </el-col>
                          <el-col :span="10"></el-col>
                        </el-row>
                        <el-form-item label="额外收取下级分润比例：">
                          <el-input v-model="list.pivot.extra_cost" placeholder="请输入额外收取下级比例" />
                        </el-form-item>
                      </div>
                    </el-card>
                    <br />
                  </div>
                  <div v-if="list.code=='bankcard' || list.id==4">
                    <el-card shadow="hover">
                      <template #header>
                        <div class="card-header"><span>{{list.name}}</span></div>
                      </template>
                      <div class="card-form">
                        <el-row>
                          <el-col :span="12">
                            <el-form-item label="借记卡(%):">
                              <el-input v-model="list.pivot.cost.debitcard.cost" placeholder="请输入借记卡费率" />
                              <span class="describe">成本{{list.cost.debitcard.cost}} </span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1">
                          </el-col>
                          <el-col :span="8">
                            <el-input v-model="list.pivot.cost.debitcard.total" placeholder="请输入成本" />
                            <span class="describe">成本 {{list.cost.debitcard.total}}</span>
                          </el-col>
                          <el-col :span="1">
                            <p class="percentage">元</p>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="12">
                            <el-form-item label="信用卡(%):">
                              <el-input v-model="list.pivot.cost.creditcard.cost" placeholder="请输入信用卡费率" />
                              <span class="describe">成本 {{list.cost.creditcard.cost}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12"></el-col>
                        </el-row>
                        <el-form-item label="额外收取下级分润比例(%):">
                          <el-input v-model="list.pivot.extra_cost" placeholder="请输入额外收取下级比例" />
                        </el-form-item>
                      </div>
                    </el-card>
                    <br />
                  </div>
                </div>
                <div class="submit-btn">
                  <el-button type="primary" @click="handleSeveConfig(item)">保存设置</el-button>
                </div>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </el-dialog>
    <!-- 配置费率 end -->
    <!-- 分配设备 -->
    <el-dialog v-model="assingnBtn" title="分配设备" width="70%">
      <!-- 已绑定 -->
      <div class="table-title">
        <p>已绑定设备列表</p>
        <el-button type="primary" @click="handleBoundShow">
          <el-icon style="margin-right: 0.625rem; color: #ffffff;">
            <Plus />
          </el-icon>
          绑定设备
        </el-button>
      </div>
      <el-table :data="boundData" border style="width: 100%">
        <el-table-column label="序号" width="80" type="index" align="center">
          <template #default="scope">
            {{ (boundPage.currentPage -1) * boundPage.page +scope.$index+1}}
          </template>
        </el-table-column>
        <el-table-column prop="serial_number" label="设备序列号">
          <template #default="scope">
            {{ scope.row.serial_number }}
            <el-icon>
              <CopyDocument @click="handleCopy(scope.row.serial_number)" style="color:#52acff" />
            </el-icon>
          </template>
        </el-table-column>
        <el-table-column prop="device_category_name" label="设备分类" width="160px">
          <template #default="scope">
            <el-tag>{{scope.row.device_category_name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="设备型号" width="160px">
          <template #default="scope">
            <el-tag type="warning">{{scope.row.type}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间">
          <template #default="scope">
            <span>{{dayjs(scope.row.created_at).format("YYYY-M-D")}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-link :underline="false" type="primary" @click="handleUnbind(scope.row)">解绑</el-link>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="空数据" :image-size="300" />
        </template>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :hide-on-single-page=true :total=boundPage.total @current-change="handleboundPage" :current-page=boundPage.currentPage :page-count="boundPage.totalPages" />
      </div>
      <!-- 已绑定 end -->
      <!-- 未绑定 -->
      <el-dialog v-model="bindisshow" width="68%" title="分配设备" append-to-body>
        <div class="table-title">
          <p>未绑定设备列表</p>
        </div>
        <el-table :data="unboundTable" ref="multipleTableRef" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column label="序号" width="80" type="index" align="center">
            <template #default="scope">
              {{ (unboundpage.currentPage -1) * unboundpage.perPage +scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column prop="serial_number" label="设备序列号">
            <template #default="scope">
              {{ scope.row.serial_number }}
              <el-icon>
                <CopyDocument @click="handleCopy(scope.row.serial_number)" style="color:#52acff" />
              </el-icon>
            </template>
          </el-table-column>
          <el-table-column prop="device_category.name" label="设备分类" align="center">
            <template #default="scope">
              <el-tag>{{scope.row.device_category.name}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="device_type.name" label="设备型号" align="center">
            <template #default="scope">
              <el-tag type="warning">{{scope.row.device_type.name}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" align="center">
            <template #default="scope">
              <span>{{dayjs(scope.row.created_at).format("YYYY-M-D")}}</span>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty description="空数据" :image-size="200" />
          </template>
        </el-table>

        <div class="unpagination">
          <el-pagination background layout="prev, pager, next" :hide-on-single-page=true :total=unboundpage.total @current-change="handleundbindPage" :current-page=unboundpage.currentPage :page-count=unboundpage.totalPages />
        </div>
        <template #footer>
          <div class=" dialog-footer">
            <el-button @click="handleBoundShow">取消</el-button>
            <el-button type="primary" @click="handleBindConfirm">绑定</el-button>
          </div>
        </template>
      </el-dialog>
      <!-- 未绑定 end -->
    </el-dialog>
    <!-- 分配设备 end-->
    <el-dialog v-model=channelShow title="分配通道">
      <div style="display: flex; align-items: center; justify-content: flex-end;">
        <el-button type="primary" @click="handleAddChannel">新增通道</el-button>
      </div>
      <el-table :data="channelData" style="width: 100%">
        <el-table-column prop="payment_channel.name" label="通道名称" />
        <el-table-column prop="payment_channel.code" label="通道编号" />
        <el-table-column prop="payment_channel.settlement_type" label="结算周期" />
         <el-table-column prop="status" label="通道状态">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.status==1">已启用</el-tag>
            <el-tag type="info" v-else>已禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template #default="scope">
            <span>{{dayjs(scope.row.created_at).format("YYYY-MM-DD")}} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <div style="display: flex; justify-content: space-evenly; align-items: center;">
              <el-link :underline="false" type="primary" @click="hanledChangeChannel(scope.row)">编辑</el-link>
              <el-popconfirm title="确定删除该通道" @confirm="hanledeleteChannel(scope.row)">
                <template #reference>
                  <el-link :underline="false" type="primary">删除</el-link>
                </template>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog v-model="addChannelShow" width="50%"
        title="新增通道"
        append-to-body>
        <el-form label-width="180px"
        :model="addChanneData"
        >
          <el-form-item label="通道">
            <el-select v-model="addChanneData.channel_id" placeholder="请选择通道" >
              <el-option v-for="(item,index) in channel_options" :key="index" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <div v-if="addChanneData.channel_id==4">
              <el-form-item label="AppID:">
                <el-input v-model="addChanneData.config.app_id" placeholder="请输入AppID" clearable />
              </el-form-item>
              <el-form-item label="AppSecret:">
                <el-input v-model="addChanneData.config.app_secret" placeholder="请输入AppSecret" clearable />
              </el-form-item>
          </div>
          <div v-else-if="addChanneData.channel_id==3">
            <el-form-item label="OrgID:">
              <el-input v-model="addChanneData.config.org_id" placeholder="请输入OrgID" clearable />
            </el-form-item>
            <el-form-item label="PrivateKEY(私钥):">
              <el-input v-model="addChanneData.config.private_key" placeholder="请输入PrivateKEY(私钥)" clearable type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" />
            </el-form-item>
            <el-form-item label="PublicKEY(公钥):">
              <el-input v-model="addChanneData.config.public_key" placeholder="请输入PublicKEY(公钥)" clearable type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" />
            </el-form-item>
          </div>
          <div v-else-if="addChanneData.channel_id==2">
            <el-form-item label="AppID:">
              <el-input v-model="addChanneData.config.app_id" placeholder="请输入AppID" clearable />
            </el-form-item>
            <el-form-item label="MchID:">
              <el-input v-model="addChanneData.config.mch_id" placeholder="请输入MuchID" clearable />
            </el-form-item>
            <el-form-item label="KEY:">
              <el-input v-model="addChanneData.config.key" placeholder="请输入KEY" clearable />
            </el-form-item>
            <el-form-item label="CertPath:">
              <el-input v-model="addChanneData.config.cert_path" placeholder="请输入CertPath" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="KEYPath:">
              <el-input v-model="addChanneData.config.key_path" placeholder="请输入KEYPath" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
          </div>
          <div v-else-if="addChanneData.channel_id==1">
            <el-form-item label="AppID:">
              <el-input v-model="addChanneData.config.app_id" placeholder="请输入AppID" clearable />
            </el-form-item>
            <el-form-item label="应用私钥:">
              <el-input v-model="addChanneData.config.app_secret_cert" placeholder="请输入应用私钥" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="应用公钥证书:">
              <el-input v-model="addChanneData.config.app_public_cert_path" placeholder="请输入应用公钥证书" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="支付宝公钥证书:">
              <el-input v-model="addChanneData.config.alipay_public_cert_path" placeholder="请输入支付宝公钥证书" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="支付宝根证书:">
              <el-input v-model="addChanneData.config.alipay_root_cert_path" placeholder="请输入支付宝根证书" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <el-button @click="addChannelShow = false">返回</el-button>
          <el-button type="primary" @click="handelSeveChanneData(true)">确定</el-button>
        </template>
      </el-dialog>
      <el-dialog v-model="editChannelShow" width="50%"
        title="编辑通道"
        append-to-body>
        <el-form label-width="180px" :model="editChannelData">
          <el-form-item label="通道状态:">
            <el-select v-model="editChannelData.status" placeholder="请选择通道状态">
              <el-option
                v-for="item in statusOptiont"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div v-if="editChannelData.channel_id==4">
              <el-form-item label="AppID:">
                <el-input v-model="editChannelData.config.app_id" placeholder="请输入AppID" clearable />
              </el-form-item>
              <el-form-item label="AppSecret:">
                <el-input v-model="editChannelData.config.app_secret" placeholder="请输入AppSecret" clearable />
              </el-form-item>
          </div>
          <div v-else-if="editChannelData.channel_id==3">
            <el-form-item label="OrgID:">
              <el-input v-model="editChannelData.config.org_id" placeholder="请输入OrgID" clearable />
            </el-form-item>
            <el-form-item label="PrivateKEY(私钥):">
              <el-input v-model="editChannelData.config.private_key" placeholder="请输入PublicKEY(私钥)" clearable type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" />
            </el-form-item>
            <el-form-item label="PublicKEY(公钥):">
              <el-input v-model="editChannelData.config.public_key" placeholder="请输入PublicKEY(公钥)" clearable type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" />
            </el-form-item>
          </div>
          <div v-else-if="editChannelData.channel_id==2">
            <el-form-item label="AppID:">
              <el-input v-model="editChannelData.config.app_id" placeholder="请输入AppID" clearable />
            </el-form-item>
            <el-form-item label="MchID:">
              <el-input v-model="editChannelData.config.mch_id" placeholder="请输入MuchID" clearable />
            </el-form-item>
            <el-form-item label="KEY:">
              <el-input v-model="editChannelData.config.key" placeholder="请输入KEY" clearable />
            </el-form-item>
            <el-form-item label="CertPath:">
              <el-input v-model="editChannelData.config.cert_path" placeholder="请输入CertPath" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="KEYPath:">
              <el-input v-model="editChannelData.config.key_path" placeholder="请输入KEYPath" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
          </div>
          <div v-else-if="editChannelData.channel_id==1">
            <el-form-item label="AppID:">
              <el-input v-model="editChannelData.config.app_id" placeholder="请输入AppID" clearable />
            </el-form-item>
            <el-form-item label="应用私钥:">
              <el-input v-model="editChannelData.config.app_secret_cert" placeholder="请输入应用私钥" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="应用公钥证书:">
              <el-input v-model="editChannelData.config.app_public_cert_path" placeholder="请输入应用公钥证书" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="支付宝公钥证书:">
              <el-input v-model="editChannelData.config.alipay_public_cert_path" placeholder="请输入支付宝公钥证书" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
            <el-form-item label="支付宝根证书:">
              <el-input v-model="editChannelData.config.alipay_root_cert_path" placeholder="请输入支付宝根证书" clearable type="textarea" :autosize="{ minRows: 3, maxRows: 6 }" />
            </el-form-item>
          </div>
        </el-form>
        <template #footer>
          <el-button @click="editChannelShow = false">返回</el-button>
          <el-button type="primary" @click="handelSeveChanneData(false)">确定</el-button>
        </template>
      </el-dialog>
      <template #footer>
        <el-button @click="channelShow = false">返回</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  .el-dropdown-link {
    color: rgb(64, 158, 255);
  }
  .submit-btn {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .top-search,
  .list-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .list {
    width: 100%;

    h2 {
      font-weight: 200;
      font-size: 1.25rem;
    }

    .control-btn {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .pagination {
    margin: 1.25rem 0;
    display: flex;
    justify-content: flex-end;

    .demonstration {
      margin-bottom: 16px;
    }
  }

  .table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .config-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .card-block {
    width: 100%;

    .card-form {
      width: 90%;
      margin: 0 auto;

      .describe {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}

.unpagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.percentage {
  width: 100%;
  margin: 6px 0 0 6px;
}

.el-select {
  width: 100%;
}
</style>
